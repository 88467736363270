// Samornaopptak local.less

// X imports
@import "../../../../../../../lib/x1/css/x-0.less";
@import "../../../../../../../lib/x1/css/optional/x-consent-subtle.less";

// Local imports
@import "grade-table-calculator.less";


// English use same logo as NO
html[lang=en] #header {
  .logo {
    background-image: url("../images/logo.svg");
  }
}


// Hide RSS link
#vrtx-feed-link,
.vrtx-feed-link {
  display: none;
}
